@import "../node_modules/antd/dist/antd.css";
@import "assets/scss/global";
@import "assets/scss/utilities/utilities";

.btn-secondary {
  color: $secondary;
  border-color: $secondary;

  &:hover,
  &:focus {
    background-color: transparent;
    color: lighten($secondary, 5%);
    border-color: lighten($secondary, 5%);
  }

  &:active {
    background-color: transparent;
    color: darken($secondary, 5%);
    border-color: darken($secondary, 5%);
  }

  &.ant-btn-primary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: lighten($secondary, 5%);
      border-color: lighten($secondary, 5%);
    }

    &:active {
      color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
    }
  }

  &.ant-btn-link {
    color: $secondary;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $secondary;
    }

    &:hover {
      background-color: rgba($secondary, 0.1);
    }

    &:active {
      background-color: rgba($secondary, 0.2);
    }
  }
}

.ant-badge-status-dot {
  width: 16px;
  height: 16px;
}

.react-datepicker__day--selected {
  background-color: $perolaprimary !important;
  color: white !important;
}

.react-datepicker__day--selected:hover {
  background-color: $perolaprimary !important;
}

.react-datepicker__day--selected:focus,
.react-datepicker__day--selected:active {
  background-color: $perolaprimary !important;
  color: white !important;
}

.ant-modal-confirm-btns{
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      gap: 20px;
      align-items: center;
      justify-content: center;
}

.ant-modal-confirm-body > .anticon{
  margin: 0
}